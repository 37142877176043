
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import Avatar from '@/common/Avatar.vue';

import { SUBSCRIPTION_PLAN, SUBSCRIPTION_PLAN_TITLE } from '@/subscription';

import Logo from '@/common/Logo.vue';
import IconFeather from '@/icons/other/Feather.vue';
import IconApiConnect from '@/icons/other/ApiConnect.vue';
import { ToggleModal } from '@/store/modules/modals';
import { JobPricing } from '@/interface/pricing.interface';

@Options({
  components: {
    Avatar,
    Logo,
    IconFeather,
    IconApiConnect,
  },
  computed: {
    ...mapState(['profile', 'hijackingToken']),
  },
})
export default class Header extends Vue {
  public SUBSCRIPTION_PLAN = SUBSCRIPTION_PLAN;

  public SUBSCRIPTION_PLAN_TITLE = SUBSCRIPTION_PLAN_TITLE;

  public pricing: JobPricing | null = null;

  public upgrade(): void {
    this.$store.commit('modals/toggle', { modal: 'changeSubscription', status: true } as ToggleModal);
  }
}
