<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.5085 3.48754L20.5148 2.49379C20.4773 2.45629 20.4304 2.43988 20.3812 2.43988C20.332 2.43988 20.2851
    2.45863 20.2476 2.49379L18.464 4.27738C17.6886 3.75204 16.7732 3.47194 15.8366 3.47347C14.6366 3.47347 13.4366
    3.93051 12.5202 4.84691L10.132 7.23519C10.0971 7.27043 10.0775 7.31802 10.0775 7.36761C10.0775 7.41721 10.0971
    7.4648 10.132 7.50004L16.4999 13.868C16.5374 13.9055 16.5843 13.9219 16.6335 13.9219C16.6804 13.9219 16.7296
    13.9032 16.7671 13.868L19.1554 11.4797C20.7702 9.86254 20.9601 7.36176 19.7249 5.53832L21.5085 3.75472C21.5812
    3.67972 21.5812 3.56019 21.5085 3.48754ZM18.0257 10.3524L16.6335 11.7446L12.2554 7.36644L13.6476 5.97425C14.2312
    5.39066 15.0093 5.06722 15.8366 5.06722C16.664 5.06722 17.4398 5.38832 18.0257 5.97425C18.6093 6.55785 18.9327
    7.33597 18.9327 8.16332C18.9327 8.99066 18.6093 9.76644 18.0257 10.3524ZM13.5679 12.8133C13.5327 12.7784 13.4851
    12.7588 13.4355 12.7588C13.3859 12.7588 13.3383 12.7784 13.3031 12.8133L11.7421 14.3743L9.62571 12.2578L11.189
    10.6946C11.2616 10.6219 11.2616 10.5024 11.189 10.4297L10.3359 9.5766C10.3006 9.5417 10.253 9.52213 10.2034
    9.52213C10.1539 9.52213 10.1063 9.5417 10.071 9.5766L8.50774 11.1399L7.49993 10.1321C7.48243 10.1146 7.46158
    10.1008 7.43863 10.0915C7.41567 10.0822 7.39108 10.0777 7.36633 10.0782C7.31946 10.0782 7.27024 10.0969 7.23274
    10.1321L4.8468 12.5203C3.23196 14.1375 3.04212 16.6383 4.27727 18.4618L2.49368 20.2453C2.45878 20.2806 2.43921
    20.3282 2.43921 20.3778C2.43921 20.4274 2.45878 20.475 2.49368 20.5102L3.48743 21.5039C3.52493 21.5414 3.5718
    21.5578 3.62102 21.5578C3.67024 21.5578 3.71712 21.5391 3.75462 21.5039L5.53821 19.7203C6.32805 20.2571 7.2468
    20.5243 8.16555 20.5243C9.36555 20.5243 10.5656 20.0672 11.482 19.1508L13.8702 16.7625C13.9429 16.6899 13.9429
    16.5703 13.8702 16.4977L12.8624 15.4899L14.4257 13.9266C14.4984 13.8539 14.4984 13.7344 14.4257 13.6618L13.5679
    12.8133ZM10.3523 18.0258C10.0654 18.3141 9.72426 18.5428 9.34853 18.6984C8.97279 18.8541 8.56992 18.9338 8.16321
    18.9328C7.33587 18.9328 6.56008 18.6118 5.97415 18.0258C5.68582 17.739 5.45721 17.3978 5.30153 17.0221C5.14584
    16.6463 5.06617 16.2435 5.06712 15.8368C5.06712 15.0094 5.38821 14.2336 5.97415 13.6477L7.36633 12.2555L11.7445
    16.6336L10.3523 18.0258Z"/>
  </svg>
</template>
