
import { Options, Vue } from 'vue-class-component';

import { footerLinks } from '@/settings';

@Options({})
export default class LayoutFooter extends Vue {
  public links = footerLinks;

  public currentYear = new Date().getUTCFullYear();
}
