<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M176.85 46.5389C176.85 84.9666 196.756 111.356 196.756 129.872C196.756 148.389 186.111 187.278 100.928 187.278C15.7446 187.278 3.23901 134.961 3.23901 114.594C3.23901 19.2222 176.85 -21.5167 176.85 46.5389Z" fill="#FDFFFC"/>
    <path d="M141.667 27.7777C137.072 27.7777 133.333 24.0389 133.333 19.4444C133.333 14.85 137.072 11.1111 141.667 11.1111C146.261 11.1111 150 14.85 150 19.4444C150 24.0389 146.261 27.7777 141.667 27.7777ZM141.667 16.6666C140.133 16.6666 138.889 17.9166 138.889 19.4444C138.889 20.9722 140.133 22.2222 141.667 22.2222C143.2 22.2222 144.445 20.9722 144.445 19.4444C144.445 17.9166 143.2 16.6666 141.667 16.6666Z" fill="#C4D6F0"/>
    <path d="M151.389 156.944H48.6111C42.4722 156.944 37.5 151.972 37.5 145.833V62.5H162.5V145.833C162.5 151.972 157.528 156.944 151.389 156.944Z" fill="#F3F7FC"/>
    <path d="M162.5 62.5H37.5V54.1667C37.5 48.0278 42.4722 43.0555 48.6111 43.0555H151.389C157.528 43.0555 162.5 48.0278 162.5 54.1667V62.5Z" fill="#FFA400"/>
    <path d="M51.3889 145.833V62.5H37.5V145.833C37.5 151.972 42.4722 156.944 48.6111 156.944H62.5C56.3611 156.944 51.3889 151.972 51.3889 145.833Z" fill="#FDFFFC"/>
    <path d="M61.1111 43.0555H48.6111C42.4722 43.0555 37.5 48.0278 37.5 54.1667V62.5H50V54.1667C50 48.0278 54.9722 43.0555 61.1111 43.0555Z" fill="#FFA400"/>
    <path d="M101.389 127.778H98.6111C93.25 127.778 88.8889 123.417 88.8889 118.056V97.2222C88.8889 93.3778 91.4334 88.8889 98.6111 88.8889H101.389C108.567 88.8889 111.111 93.3778 111.111 97.2222V118.056C111.111 123.417 106.75 127.778 101.389 127.778ZM98.6111 97.2222C97.5889 97.2222 97.0778 97.4056 96.9667 97.4723L97.2222 118.056C97.2222 118.822 97.8445 119.444 98.6111 119.444H101.389C102.156 119.444 102.778 118.822 102.778 118.056V97.3C102.667 97.3445 102.183 97.2222 101.389 97.2222H98.6111Z" fill="#383B53"/>
    <path d="M77.7779 111.111H65.2779C59.9168 111.111 55.5557 106.75 55.5557 101.389V88.8889H63.889V101.389C63.889 102.156 64.5112 102.778 65.2779 102.778H77.7779V111.111Z" fill="#383B53"/>
    <path d="M69.4443 88.8889H77.7777V127.778H69.4443V88.8889Z" fill="#383B53"/>
    <path d="M144.444 111.111H131.944C126.583 111.111 122.222 106.75 122.222 101.389V88.8889H130.555V101.389C130.555 102.156 131.178 102.778 131.944 102.778H144.444V111.111Z" fill="#383B53"/>
    <path d="M136.111 88.8889H144.445V127.778H136.111V88.8889Z" fill="#383B53"/>
    <path d="M151.389 161.111H48.611C40.1833 161.111 33.3333 154.261 33.3333 145.833V54.1667C33.3333 45.7389 40.1833 38.8889 48.611 38.8889H151.389C159.817 38.8889 166.667 45.7389 166.667 54.1667V145.833C166.667 154.261 159.817 161.111 151.389 161.111ZM48.611 47.2222C44.7833 47.2222 41.6666 50.3389 41.6666 54.1667V145.833C41.6666 149.661 44.7833 152.778 48.611 152.778H151.389C155.217 152.778 158.333 149.661 158.333 145.833V54.1667C158.333 50.3389 155.217 47.2222 151.389 47.2222H48.611Z" fill="#383B53"/>
    <path d="M37.5 58.3334H162.5V66.6667H37.5V58.3334Z" fill="#383B53"/>
    <path d="M11.1111 152.778H188.889V161.111H11.1111V152.778Z" fill="#383B53"/>
  </svg>
  <!-- eslint-enable -->
</template>
