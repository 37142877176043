
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import Header from '@/layouts/components/Header.vue';
import Footer from '@/layouts/components/Footer.vue';

import Icon404Error from '@/icons/other/404Error.vue';

@Options({
  components: {
    Icon404Error,
    Header,
    Footer,
  },
  computed: {
    ...mapState(['profile']),
  },
})
export default class NotFoundPage extends Vue {
  public goBack(): void {
    this.$router.go(-1);
  }
}
