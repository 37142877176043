<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 169 169" fill="black" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M59.375 82.4062C56.5985 82.4062 53.9357 83.5092 51.9725 85.4725C50.0092 87.4357 48.9062 90.0985 48.9062 92.875C48.9062 95.6515 50.0092 98.3143 51.9725 100.278C53.9357 102.241 56.5985 103.344 59.375 103.344C62.1515 103.344 64.8143 102.241 66.7775 100.278C68.7408 98.3143 69.8438 95.6515 69.8438 92.875C69.8438 90.0985 68.7408 87.4357 66.7775 85.4725C64.8143 83.5092 62.1515 82.4062 59.375 82.4062ZM109.625 82.4062C106.849 82.4062 104.186 83.5092 102.222 85.4725C100.259 87.4357 99.1562 90.0985 99.1562 92.875C99.1562 95.6515 100.259 98.3143 102.222 100.278C104.186 102.241 106.849 103.344 109.625 103.344C112.401 103.344 115.064 102.241 117.028 100.278C118.991 98.3143 120.094 95.6515 120.094 92.875C120.094 90.0985 118.991 87.4357 117.028 85.4725C115.064 83.5092 112.401 82.4062 109.625 82.4062ZM84.5 0.75C38.27 0.75 0.75 38.27 0.75 84.5C0.75 130.73 38.27 168.25 84.5 168.25C130.73 168.25 168.25 130.73 168.25 84.5C168.25 38.27 130.73 0.75 84.5 0.75ZM84.5 151.5C47.5662 151.5 17.5 121.434 17.5 84.5C17.5 82.0713 17.6675 79.6425 17.9188 77.2975C37.6838 68.5038 53.345 52.34 61.5525 32.3237C71.1273 45.9112 84.5381 56.3331 100.069 62.2561C115.6 68.1791 132.545 69.3338 148.736 65.5725C150.495 71.5187 151.5 77.8838 151.5 84.5C151.5 121.434 121.434 151.5 84.5 151.5Z"
      fill="#C4D6F0"
    />
  </svg>
  <!-- eslint-enable -->
</template>
