import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconDefaultAvatar = _resolveComponent("IconDefaultAvatar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["avatar", [{ border: _ctx.border, border: _ctx.border }, _ctx.profile.subscription.plan]]),
    ref: "wrapper"
  }, [
    (_ctx.avatar)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.avatar,
          alt: "avatar profile"
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createBlock(_component_IconDefaultAvatar, { key: 1 }))
  ], 2))
}