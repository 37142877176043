
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import IconDefaultAvatar from '@/icons/other/DefaultAvatar.vue';

@Options({
  components: {
    IconDefaultAvatar,
  },
  props: {
    border: Boolean,
    size: Number,
  },
  computed: {
    ...mapState(['profile']),
  },
})
export default class Avatar extends Vue {
  declare $refs: {
    wrapper: HTMLDivElement;
  }

  declare $props: {
    border?: string;
    size?: number;
  }

  public avatar: string | null = null;

  private subscribe: any;

  private buildStyle(): void {
    const styles: { [key: string]: any } = {};

    if (this.$props?.size) {
      const size = this.$props.size!;

      Object.assign(styles, {
        'max-width': `${size}px`,
        'max-height': `${size}px`,
        'min-width': `${size}px`,
        'min-height': `${size}px`,
      });
    }

    Object.keys(styles).forEach((key: string) => {
      this.$refs.wrapper.style.setProperty(key, styles[key]);
    });
  }

  private updateAvatar(): void {
    this.avatar = sessionStorage.getItem('avatar') || null;
  }

  created(): void {
    this.subscribe = this.$store.subscribe((m) => {
      if (m.type === 'update' && m.payload === 'avatar') {
        this.updateAvatar();
      }
    });
  }

  mounted(): void {
    this.buildStyle();

    this.updateAvatar();
  }

  unmounted(): void {
    this.subscribe();
  }
}
